import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Navigation.module.css';

const Navigation = ({ menuOpen, setMenuOpen }) => {
  return (
    <nav className={styles.topNav}>
      <img 
        src={`/.netlify/images?url=${encodeURIComponent('/assets/images/space_logo.png')}&w=200&h=40&fit=contain&fm=webp`}
        alt="Spacefruit Logo" 
        className={styles.navLogo}
      />
      <div className={styles.hamburger} onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={`${styles.navLinks} ${menuOpen ? styles.active : ''}`}>
        <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
        <li><Link to="/ape-club" onClick={() => setMenuOpen(false)}>Ape Club</Link></li>
        <li><Link to="/moose-club" onClick={() => setMenuOpen(false)}>Moose Club</Link></li>
        <li><Link to="/contact" onClick={() => setMenuOpen(false)}>Contact</Link></li>
      </ul>
    </nav>
  );
};

export default Navigation;