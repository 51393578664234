import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';

const ApeClub = React.lazy(() => import('./pages/ApeClub'));
const MooseClub = React.lazy(() => import('./pages/MooseClub'));
const Contact = React.lazy(() => import('./pages/Contact'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ape-club" element={<ApeClub />} />
        <Route path="/moose-club" element={<MooseClub />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;