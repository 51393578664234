import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Home.module.css';
import TeaserBanner from '../components/TeaserBanner';
import Navigation from '../components/Navigation'; // Adjust the path if necessary

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const sectionsRef = useRef([]);

  const getOptimizedImageUrl = (src, width, height) => {
    return `/.netlify/images?url=${encodeURIComponent(src)}&w=${width}&h=${height}&fit=cover&fm=webp`;
  };
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.visible);
          } else {
            entry.target.classList.remove(styles.visible);
          }
        });
      },
      { 
        threshold: 0.1,
        rootMargin: "-100px 0px"
      }
    );
  
    const currentSections = sectionsRef.current;
  
    currentSections.forEach((section) => {
      if (section) observer.observe(section);
    });
  
    return () => {
      currentSections.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
      <div className={styles.homeScreen}>
        <Navigation menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
  
        <section 
        id="home"
        ref={(el) => (sectionsRef.current[0] = el)} 
        className={`${styles.parallaxSection} ${styles.intro}`}
        style={{
          backgroundImage: `url(${getOptimizedImageUrl('/assets/images/spacefruit-background.png', 2912, 1632)})`
        }}
      >
        <div className={styles.content}>
          <h1>Welcome to Spacefruit.io</h1>
          <p>Embark on a juicy adventure through the cosmos! Explore worlds where apes lounge in zero-G and moose toast marshmallow stars.</p>
        </div>
        <div className={styles.scrollIndicator}>Scroll to Explore Our Fruity Universe</div>
      </section>

      <section 
        id="ape-club"
        ref={(el) => (sectionsRef.current[1] = el)} 
        className={`${styles.parallaxSection} ${styles.apeClub}`}
        style={{
          backgroundImage: `url(${getOptimizedImageUrl('/assets/images/ape-club-background.png', 2912, 1632)})`
        }}
      >
        <div className={styles.content}>
          <h2>Stellar Ape Club</h2>
          <p>Where cool primates chill in zero-G. Swing by for cosmic cocktails and out-of-this-world vibes!</p>
          <Link to="/ape-club" className={styles.exploreBtn}>Join the Space Crew</Link>
        </div>
      </section>
        <section 
        id="moose-club"
        ref={(el) => (sectionsRef.current[2] = el)} 
        className={`${styles.parallaxSection} ${styles.mooseClub}`}
        style={{
          backgroundImage: `url(${getOptimizedImageUrl('/assets/images/moose-club-background.png', 2912, 1632)})`
        }}
      >
        <div className={styles.content}>
          <h2>Cosmic Moose Club</h2>
          <p>Join the cosmic campfire, roast some star-mallows, and swap tales with our antlered astronauts!</p>
          <Link to="/moose-club" className={styles.exploreBtn}>Join the Moose-tery</Link>
        </div>
      </section>

      <section 
        ref={(el) => (sectionsRef.current[3] = el)} 
        className={`${styles.parallaxSection} ${styles.teaserSection}`}
      >
        <TeaserBanner />
      </section>
    </div>
  );
};

export default Home;