import React from 'react';
import styles from '../styles/TeaserBanner.module.css';

const TeaserBanner = () => {
  return (
    <div className={styles.teaserContainer}>
      <div className={styles.starBackground}>
        <div className={styles.teaserContent}>
          <h2>Celebrity Club and More Coming Soon</h2>
          <p>Stay tuned for our next cosmic adventure!</p>
        </div>
      </div>
    </div>
  );
};

export default TeaserBanner;